export const FirebaseConfig = {
	"projectId": "bantoo-taxi-826b9",
	"appId": "1:161554259504:web:803f7fa7235ab0d8b497f5",
	"databaseURL": "https://bantoo-taxi-826b9-default-rtdb.firebaseio.com",
	"storageBucket": "bantoo-taxi-826b9.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAyvBHk2aK_6tzpcr_WfRXxkdE6s9lYZKY",
	"authDomain": "bantoo-taxi-826b9.firebaseapp.com",
	"messagingSenderId": "161554259504",
	"measurementId": "G-ZQYP6RMBXF"
};